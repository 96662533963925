// /var/www/website2024/192.testproject.work/website2024/S-307-napharun-getdat/myapp/src/components/MainContent.js

import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import 'primereact/resources/themes/saga-blue/theme.css';  // Choose your theme
import 'primereact/resources/primereact.min.css';          // Core CSS
import 'primeicons/primeicons.css';                        // Icons
import { Container, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';  // Import bootstrap CSS
import '../style/App.scss';  // Import the new SCSS file
import { Link } from 'react-router-dom';  // Only Link is needed now, no useNavigate
import fetchData from '../api/fetchData';

const MainContent = () => {
  const [data, setData] = useState([]);
  const [limit] = useState(20);  // Default limit of 10 items per page
  const [offset, setOffset] = useState(0);  // Initial offset is 0
  const [loading, setLoading] = useState(true);  // Loading state for better UX
  const [totalRecords] = useState(1695);  // Total records from your dataset
  const [currentPage, setCurrentPage] = useState(1);  // Keep track of the current page

  const totalPages = Math.ceil(totalRecords / limit);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const fetchedData = await fetchData(limit, offset);  // Use fetchData function
        setData(fetchedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [limit, offset]);

  const handleNext = () => {
    if (currentPage < totalPages) {
      setOffset(prevOffset => prevOffset + limit);
      setCurrentPage(prevPage => prevPage + 1);  // Move to the next page
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setOffset(prevOffset => prevOffset - limit);
      setCurrentPage(prevPage => prevPage - 1);  // Move to the previous page
    }
  };

  const handlePageClick = (page) => {
    const newOffset = (page - 1) * limit;
    setOffset(newOffset);
    setCurrentPage(page);
  };

  return (
    <Container>
      <Row className="mt-3">
      {
  loading ? (
    <p>Loading data...</p>
  ) : data.length ? (
    data.map((item, index) => {
      // Check for "No images found." in image_link and apply fallback
      const imageUrl = item.image_link && item.image_link.length > 0 && !item.image_link[0].includes("No images found.")
        ? `https://236.testproject.work/file/img-2/${item.image_link[0]}`
        : 'https://afaa.website/s/9c25ab.svg';  // Fallback image

      const title = item.extracted_text ? item.extracted_text.substring(0, 50) : 'No title available';

      const footer = (
        <span className="p-buttonset">
          {item.data_bot_id ? (
            <Link to={`/Details/${item.data_bot_id}`} className="p-button p-component">
              รายละเอียด
            </Link>
          ) : (
            <button className="p-button p-component" disabled>
              รายละเอียด
            </button>
          )}
      
          {/* Check for PDF link and display if available */}
          {item.pdf_link && item.pdf_link.length > 0 ? (
            <a 
              href={`https://236.testproject.work/file/pdf-3/${item.pdf_link[0]}`} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="p-button p-component"
            >
              PDF
            </a>
          ) : (
            <button className="p-button p-component" disabled>
              PDF
            </button>
          )}
        </span>
      );
      

      return (
        <Col key={index} xs={12} sm={6} md={4} lg={3} className="d-flex">
          <Card
            title={title}
            subTitle={item.extracted_text || 'No subtitle available'}
            footer={footer}
            header={<img alt="Card" src={imageUrl} />}  // Updated header with fallback image condition
            className="mb-3 d-flex flex-column"
          >
            <p className="m-0" style={{ flexGrow: 1 }}>
              {item.extracted_text ? item.extracted_text.substring(0, 10) + '...' : 'No description available.'}
            </p>
          </Card>
        </Col>
      );
    })
  ) : (
    <p>No data available</p>
  )
}


      </Row>

      {/* Pagination controls */}
      <div>
        <Col>
          <Button onClick={handlePrevious} disabled={currentPage === 1}>
            Previous
          </Button>
        </Col>
        <Col className="text-center">
          {Array.from({ length: totalPages }, (_, index) => (
            <Button
              key={index}
              onClick={() => handlePageClick(index + 1)}
              variant={currentPage === index + 1 ? 'primary' : 'light'}
              className="mx-1"
            >
              {index + 1}
            </Button>
          ))}
        </Col>
        <Col className="text-right">
          <Button onClick={handleNext} disabled={currentPage === totalPages}>
            Next
          </Button>
        </Col>
      </div>
    </Container>
  );
};

export default MainContent;
