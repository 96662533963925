// /var/www/website2024/192.testproject.work/website2024/S-307-napharun-getdat/myapp/src/components/Navigation.js

// src/components/Navigation.js
import React, { useState } from 'react';
import { Menubar } from 'primereact/menubar';
import { useNavigate } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import axios from 'axios';

const Navigation = () => {
  const [loading, setLoading] = useState(false);  // State for spinner
  const navigate = useNavigate();
  const token = localStorage.getItem('token');  // Check for token

// src/components/Navigation.js

const handleLogout = async () => {
  setLoading(true);  // Show spinner on logout start

  try {
    await axios.post('https://py-maracar.afaa.website/logout/api/checkout', {}, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`  // Include token in Authorization header
      }
    });
    localStorage.removeItem('token');
    localStorage.removeItem('userID');
    setLoading(false);  // Stop spinner after logout success
    navigate('/login');
  } catch (error) {
    console.error('Error during logout', error);
    setLoading(false);  // Stop spinner on error
  }
};


  const items = token ? [
    { label: 'Home', icon: 'pi pi-fw pi-home', command: () => navigate('/') },
    { label: 'Profile', icon: 'pi pi-fw pi-user', command: () => navigate('/profile') },
    { label: 'Webboard', icon: 'pi pi-fw pi-comments', command: () => navigate('/webboard') },
    { label: 'Logout', icon: 'pi pi-fw pi-sign-out', command: handleLogout },
  ] : [
    { label: 'Home', icon: 'pi pi-fw pi-home', command: () => navigate('/') },
    { label: 'Webboard', icon: 'pi pi-fw pi-comments', command: () => navigate('/webboard') },
    { label: 'Login', icon: 'pi pi-fw pi-sign-in', command: () => navigate('/login') },
    { label: 'Register', icon: 'pi pi-fw pi-user-plus', command: () => navigate('/register') }
  ];

  return (
    <div>
      <Menubar model={items} />
      {loading && <ProgressSpinner />}  {/* Show spinner during logout */}
    </div>
  );
};

export default Navigation;
