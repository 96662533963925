
// /var/www/website2024/192.testproject.work/website2024/S-307-napharun-getdat/myapp/src/components/Login.js
// src/components/Login.js
import React, { useState } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { loginUser } from '../api/api';  // Import loginUser from the api.js
import { useNavigate } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);  // New state for spinner
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);  // Show spinner when login starts

    try {
      const response = await loginUser(email, password);  // Use the loginUser function from api.js

      if (response.token) {
        localStorage.setItem('token', response.token);
        localStorage.setItem('userID', response.user);
        setLoading(false);  // Stop spinner after login success
        navigate('/profile');  // Redirect to profile page
      } else {
        setError('Invalid email or password');
        setLoading(false);  // Stop spinner if login fails
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
      setLoading(false);  // Stop spinner on error
    }
  };

  return (
    <Container className="mt-4">
      <h1>Login</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      {loading && <ProgressSpinner />}  {/* Show spinner while loading */}
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="email"
          />
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="current-password"
          />
        </Form.Group>

        <Button variant="primary" type="submit" disabled={loading}>  {/* Disable button during login */}
          Login
        </Button>
      </Form>
    </Container>
  );
};

export default Login;
