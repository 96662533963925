// /var/www/website2024/192.testproject.work/website2024/S-307-napharun-getdat/myapp/src/api/fetchData.js
import axios from 'axios';

const fetchData = async (limit, offset) => {
  try {
    const response = await axios.get(`https://236.testproject.work/api/v1/read-data-bot?limit=${limit}&offset=${offset}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export default fetchData;
