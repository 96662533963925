// /var/www/website2024/192.testproject.work/website2024/S-307-napharun-getdat/myapp/src/api/api.js

import axios from 'axios';

const BASE_API_URL = 'https://py-maracar.afaa.website/';


export const getAddCommentApiUrl = () => {
  return `${BASE_API_URL}reviews/api_env_3/create`; 
};

// New function for fetching reviews with updated API URL
export const getReviewsApiUrl = () => {
  return `${BASE_API_URL}reviews/api_env_3/view?branch_user=4393&sort_by=review_id&sort_order=DESC&topic_id=0`;
};

// Rest of the API code remains unchanged



// Function to return headers with Authorization token
const getHeaders = () => {
  const token = localStorage.getItem('token');  // Retrieve token from localStorage
  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,  // Add token to the Authorization header
  };
};

// Function to handle user login
export const loginUser = async (email, password) => {
  try {
    const response = await axios.post(`${BASE_API_URL}login/api_env_3/check`, {
      email,
      password
    }, {
      headers: getHeaders()  // Use the dynamic headers function
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to fetch user profile
export const getUserProfile = async (userID) => {
  try {
    const response = await axios.get(`${BASE_API_URL}register/api_env_3/user?user_ID=${userID}`, {
      headers: getHeaders()  // Use the dynamic headers function
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};


// Update user profile (change PUT to POST)
export const updateUserProfile = async (userID, updatedData) => {
  try {
    const response = await axios.post(`${BASE_API_URL}register/api_env_3/edit?user_ID=${userID}`, updatedData, {
      headers: getHeaders()  // Use the dynamic headers function
    });
    return response.data;
  } catch (error) {
    console.error('Server error during profile update:', error.response ? error.response.data : error);
    throw new Error('Failed to update user');  // Maintain the error message for UI
  }
};


