// /var/www/website2024/192.testproject.work/website2024/S-307-napharun-getdat/myapp/src/Details.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Button } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';  // Import Swiper components

import 'swiper/css';
import 'swiper/css/navigation'; // Import additional modules' styles if you use them

const Details = () => {
  const { id } = useParams();  // Get the item ID from the URL
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();  // Initialize navigate for the Back button

  useEffect(() => {
    axios.get(`https://236.testproject.work/api/v1/read-data-bot`, {
      params: {
        limit: 10,
        offset: 0,
        data_bot_id: id
      }
    })
      .then(response => {
        if (response.data.length > 0) {
          setData(response.data[0]);
        } else {
          setData(null);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching details:', error);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!data) {
    return <p>No details available.</p>;
  }

  return (
    <Container className="mt-4">
      <Button onClick={() => navigate(-1)} className="mb-3">Back</Button>  {/* Back Button */}

      <h1>{data?.extracted_text ? data.extracted_text.substring(0, 50) : 'No Title'}</h1>
      
      {/* Swiper component for image slides */}
      {data?.image_link && data.image_link.length > 0 ? (
        <Swiper spaceBetween={10} slidesPerView={1}>
          {data.image_link.map((image, index) => (
            <SwiperSlide key={index}>
              <img
                src={`https://236.testproject.work/file/img-2/${image}`}
                alt={`Slide ${index}`}
                style={{
                  width: '100%', 
                  height: '500px',  // Set a max height for the images to ensure they are the same height
                  objectFit: 'contain',  // Ensures the image covers the container while maintaining aspect ratio
                  boxSizing: 'border-box'
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <img src='https://via.placeholder.com/150' alt='Placeholder' />
      )}

      <p>{data?.extracted_text || 'No description available.'}</p>
    </Container>
  );
};

export default Details;
