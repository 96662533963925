// /var/www/website2024/192.testproject.work/website2024/S-307-napharun-getdat/myapp/src/components/Profile.js
import React, { useState, useEffect } from 'react';
import { getUserProfile, updateUserProfile } from '../api/api';  // Import both getUserProfile and updateUserProfile
import { Container, Spinner, Alert, Form, Button } from 'react-bootstrap';  // Import Form and Button

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const userID = localStorage.getItem('userID');  // Retrieve userID from localStorage

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await getUserProfile(userID);  // Fetch profile data using new function
        setUserData(data);
      } catch (error) {
        setError('Failed to load profile data.');
        console.error('Error fetching profile:', error);
      } finally {
        setLoading(false);  // Stop loading spinner
      }
    };

    if (userID) {
      fetchProfile();
    } else {
      setError('No user ID found. Please log in.');
      setLoading(false);
    }
  }, [userID]);

  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }
  
    // Create an updatedData object with only the required fields
    let updatedData = {
      first_name: userData.first_name,
      sur_name: userData.sur_name,
      email: userData.email,
      telephone: userData.telephone,
      password: newPassword ? newPassword : undefined,  // Include password only if it's provided
      contact: userData.contact
    };
    
    // Remove password field if not updated
    updatedData = Object.fromEntries(
      Object.entries(updatedData).filter(([_, v]) => v !== "" && v !== null)
    );
  
    console.log('Data being sent to API:', updatedData);  // Log the data being sent
    
    try {
      await updateUserProfile(userID, updatedData);  // Pass the userID and updated data to the API
      alert('Profile updated successfully!');
    } catch (err) {
      setError('Error updating profile.');
      console.error('Error during profile update:', err.response ? err.response.data : err);
    }
  };
  
  

  if (loading) {
    return <Spinner animation="border" role="status" />;  // Show spinner while loading
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;  // Show error message
  }

  return (
    <Container className="mt-4">
      <h1>Profile</h1>
      {userData ? (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formFirstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              value={userData.first_name}
              onChange={(e) => setUserData({ ...userData, first_name: e.target.value })}  // Update state
            />
          </Form.Group>

          <Form.Group controlId="formSurName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              value={userData.sur_name}
              onChange={(e) => setUserData({ ...userData, sur_name: e.target.value })}  // Update state
            />
          </Form.Group>

          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              value={userData.email}
              onChange={(e) => setUserData({ ...userData, email: e.target.value })}  // Update state
            />
          </Form.Group>

          <Form.Group controlId="formTelephone">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="text"
              value={userData.telephone}
              onChange={(e) => setUserData({ ...userData, telephone: e.target.value })}  // Update state
            />
          </Form.Group>

     
          <Form.Group controlId="formContact">
            <Form.Label>Contact</Form.Label>
            <Form.Control
              type="text"
              value={userData.contact}
              onChange={(e) => setUserData({ ...userData, contact: e.target.value })}  // Update state
            />
          </Form.Group>

          {/* New Password Fields */}
          <Form.Group controlId="formNewPassword">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}  // Update state
            />
          </Form.Group>

          <Form.Group controlId="formConfirmPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Confirm new password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}  // Update state
            />
          </Form.Group>

          {/* Submit button to update user data */}
          <Button variant="primary" type="submit">
            Update Profile
          </Button>
        </Form>
      ) : (
        <p>No profile data available.</p>
      )}
    </Container>
  );
};

export default Profile;
