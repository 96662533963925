// /var/www/website2024/192.testproject.work/website2024/S-307-napharun-getdat/myapp/src/components/Webboard.js

import React, { useState, useEffect } from 'react';
import { Container, Card, Button, Form, Alert } from 'react-bootstrap';
import { Rating } from 'primereact/rating';  // Import the Rating component
import axios from 'axios';
import { getAddCommentApiUrl, getReviewsApiUrl } from '../api/api';  // Import the URL function

// Import the SCSS module
import styles from '../style/Webboard.module.scss';

import { ProgressSpinner } from 'primereact/progressspinner';
import { Button as PrimeButton } from 'primereact/button';  // Import Button from primereact


const Webboard = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newComment, setNewComment] = useState('');
  const [newTopicTitle, setNewTopicTitle] = useState('');  // Added topic title state
  const [rating, setRating] = useState(0);  // Added rating state
  const [error, setError] = useState(null);
  const [showCommentForm, setShowCommentForm] = useState({});
  const userID = localStorage.getItem('userID');
  const [additionalReviewData, setAdditionalReviewData] = useState({});


  const [isSubmitting, setIsSubmitting] = useState(false);  // Spinner for submit button
  const [toggleLoading, setToggleLoading] = useState({});  // Spinner for toggling form


  // Toggle form visibility for each review
  const toggleCommentForm = async (review_id) => {
    setToggleLoading(prevState => ({ ...prevState, [review_id]: true }));  // Show spinner when toggling
    setShowCommentForm(prevState => ({
      ...prevState,
      [review_id]: !prevState[review_id]
    }));

    if (!showCommentForm[review_id]) {
      const review = reviews.find(r => r.review_id === review_id);
      setNewTopicTitle(review?.topic_title || '');  // Set default topic title from the review

      try {
        const response = await axios.get(`https://py-maracar.afaa.website/reviews/api_env_3/view?branch_user=4393&sort_by=review_id&sort_order=DESC&topic_id=${review_id}`);
        console.log(`Fetched review details for topic_id=${review_id}:`, response.data);

        // Store the fetched data in additionalReviewData
        setAdditionalReviewData(prevState => ({
          ...prevState,
          [review_id]: response.data
        }));
      } catch (error) {
        console.error('Error fetching review details:', error);
      }
    }
    setToggleLoading(prevState => ({ ...prevState, [review_id]: false }));  // Hide spinner
  };

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get(getReviewsApiUrl());
        setReviews(response.data);
      } catch (error) {
        console.error('Error fetching reviews:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, []);

  const addComment = async (commentData) => {
    const token = localStorage.getItem('token');  // Retrieve token from localStorage
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,  // Add token to the Authorization header
    };

    try {
      // Log the headers being sent for debugging purposes
      console.log('Headers being sent:', headers);

      const apiUrl = getAddCommentApiUrl();  // Get the API URL for adding a comment
      const response = await axios.post(apiUrl, commentData, {
        headers: headers,  // Set the headers for authorization
      });

      return response.data;
    } catch (error) {
      console.error('Error adding comment:', error.response ? error.response.data : error);
      throw new Error('Failed to add comment');
    }
  };

  const handleAddComment = async (e, review_id, userID) => {
    e.preventDefault();
    setIsSubmitting(true);  // Show spinner on submit

    const currentDate = new Date().toISOString().split('.')[0];  // Get the current date

    const commentData = {
      user_id: userID,
      table_data: 4,  // Static value
      review_typeID: 102,  // Static value
      rating: rating,  // Dynamic value
      topic_title: newTopicTitle,  // Use the preset topic title
      comment: `<p>${newComment}</p>`,  // Dynamic comment
      updated_at: currentDate,  // Current date in ISO format
      IP: "192.168.1.0",  // Static IP value
      status: 2,  // Static value
      topic_id: review_id  // Associate comment with specific review ID (review_id)
    };

    try {
      // Add the comment
      await addComment([commentData]);

      // Clear form fields
      setNewComment('');
      setNewTopicTitle('');
      setRating(0);

      // Refresh the additional review data after comment is added
      const response = await axios.get(
        `https://py-maracar.afaa.website/reviews/api_env_3/view?branch_user=4393&sort_by=review_id&sort_order=DESC&topic_id=${review_id}`
      );

      setAdditionalReviewData(prevState => ({
        ...prevState,
        [review_id]: response.data  // Update with fresh data
      }));

    } catch (error) {
      setError('Failed to add comment');
      console.error('Error adding comment:', error);
    } finally {
      setIsSubmitting(false);  // Hide spinner after submission
    }
  };



  return (
    <div className={`${styles.rootindex1}`}>
      <Container className={`mt-4 ${styles.container}`} >
        <h1 style={{ color: '#ffffff' }}>Webboard</h1>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            {reviews.map((review) => (
              <Card key={review.review_id} className={`mb-3 ${styles.ecoStyleCard}`}>
                <Card.Body style={{ padding: '8px' }}>
                  <Card.Title className={styles.cardTitle}>{review.topic_title}</Card.Title>
                  <Card.Text
                    className={styles.cardText}
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxHeight: '40px',
                    }}
                    dangerouslySetInnerHTML={{ __html: review.comment }}
                  />


                  {showCommentForm[review.review_id] && additionalReviewData[review.review_id] && (
                    <div className={styles.additionalReviewInfo}>
                      <PrimeButton
                        onClick={() => toggleCommentForm(review.review_id)}
                        className={styles.additionalReviewInfo}
                        icon={toggleLoading[review.review_id] ? <ProgressSpinner style={{ width: '20px', height: '20px' }} /> : 'pi pi-comments'}
                        label={showCommentForm[review.review_id] ? 'Hide Comment Form' : 'Show Comment Form'}
                      />

                      {additionalReviewData[review.review_id].map((info, index) => (
                        <Card key={index} className="mb-2">
                          <Card.Body>
                            <p>
                              <strong> <Rating value={info.rating} readOnly stars={5} /> </strong>
                            </p>
                            <div dangerouslySetInnerHTML={{ __html: info.comment }} />

                            <p> {new Date(info.updated_at).toLocaleString()} </p>
                          </Card.Body>
                        </Card>
                      ))}
                    </div>
                  )}


                  <Button
                    onClick={() => toggleCommentForm(review.review_id)}
                    className={styles.additionalReviewInfo}
                  >
                    {showCommentForm[review.review_id] ? 'Hide Comment Form' : 'Show Comment Form'}
                  </Button>

                  {/* Comment Form Visibility */}
                  {showCommentForm[review.review_id] && (
                    <Form onSubmit={(e) => handleAddComment(e, review.review_id, userID)} className="mt-4">
                      <Form.Group controlId={`newRating-${review.review_id}`}>
                        <Form.Label style={{ color: '#ffffff' }}>Rating</Form.Label>
                        <Rating value={rating} onChange={(e) => setRating(e.value)} stars={5} />
                      </Form.Group>

                      <Form.Group controlId={`newComment-${review.review_id}`}>
                        <Form.Label style={{ color: '#ffffff' }}>Comment</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          value={newComment}
                          onChange={(e) => setNewComment(e.target.value)}
                          placeholder="Enter your comment"
                          style={{ padding: '6px' }}
                          className={styles.formControl}
                        />
                      </Form.Group>
                      {error && <Alert variant="danger">{error}</Alert>}

                      <PrimeButton
                        type="submit"
                        className={styles.submitButton}
                        icon={isSubmitting ? <ProgressSpinner style={{ width: '20px', height: '20px' }} /> : 'pi pi-save'}
                        label="Add Comment"
                        disabled={isSubmitting}
                      />
                    </Form>
                  )}
                </Card.Body>
              </Card>
            ))}
          </>
        )}
      </Container>
    </div>
  );
};

export default Webboard;
