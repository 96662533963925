///var/www/website2024/192.testproject.work/website2024/S-307-napharun-getdat/myapp/src/App.js

// src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MainContent from './components/MainContent';
import Details from './Details';
import Navigation from './components/Navigation';
import Login from './components/Login';  // Login page
import Register from './components/Register';  // Register page
import Webboard from './components/Webboard';  // Webboard page

import Profile from './components/Profile';  // Import Profile component

const App = () => {
  return (
    <>
      <Navigation />  {/* Navbar to navigate between pages */}
      <Routes>
        <Route path="/" element={<MainContent />} />
        <Route path="/Details/:id" element={<Details />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/webboard" element={<Webboard />} />
        <Route path="/profile" element={<Profile />} />  {/* Add Profile route */}
      </Routes>
    </>
  );
};

export default App;
