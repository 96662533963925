// /var/www/website2024/192.testproject.work/website2024/S-307-napharun-getdat/myapp/src/components/Register.js

import React, { useState } from 'react';
import axios from 'axios';
import { Container, Form, Button, Alert } from 'react-bootstrap';

const Register = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    sur_name: '',
    telephone: '',
    email: '',
    password: '',
    ip: '192.168.1.15',  
    date: '2024-03-24',
    time: '22:24:04',
    image: '',
    contact: '',
    birthday: '',
    branch_ID: 2,
    position_ID: 4
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const response = await axios.post('https://py-maracar.afaa.website/register/api_env_3/insert', formData);

      if (response.data.success) {
        setSuccess(true);
      } else {
        setError('Registration failed');
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <Container className="mt-4">
      <h1>Register</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">Registration successful!</Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formFirstName">
          <Form.Label>First Name</Form.Label>
          <Form.Control type="text" name="first_name" value={formData.first_name} onChange={handleChange} />
        </Form.Group>

        <Form.Group controlId="formSurName">
          <Form.Label>Surname</Form.Label>
          <Form.Control type="text" name="sur_name" value={formData.sur_name} onChange={handleChange} />
        </Form.Group>

        <Form.Group controlId="formTelephone">
          <Form.Label>Telephone</Form.Label>
          <Form.Control type="text" name="telephone" value={formData.telephone} onChange={handleChange} />
        </Form.Group>

        <Form.Group controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} />
        </Form.Group>

        <Form.Group controlId="formPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control type="password" name="password" value={formData.password} onChange={handleChange} />
        </Form.Group>

        <Button variant="primary" type="submit">
          Register
        </Button>
      </Form>
    </Container>
  );
};

export default Register;
